import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import Marquee from 'react-fast-marquee';

const Sponsors = () => {
	var items = [
		{
			src: 'lefatshe-logo.jpeg',
		},
		// {
		// 	src: 'newthings.png',
		// },
	];

	return (
		<Box marginTop={5}>
			<Typography variant='h3' textAlign={'center'} fontWeight={800} color='secondary'>
				Sponsor
			</Typography>
			<Marquee speed={80}>
				{items.map((item, i) => (
					<Avatar
						src={item.src}
						key={i}
						sx={{
							width: i === 1 ? 400 : 200,
							height: 125,
							m: 10,
							border: '1px solid rgba(0,0,0,0.8)',
							px: 1,
							// transform: 'scale(3)',
						}}
						variant='rounded'
					/>
				))}
			</Marquee>
		</Box>
	);
};

export default Sponsors;
