import { Box, Fade, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../styles/index_styles';

const Hero = () => {
	const classes = useStyles();
	const [loaded, setLoaded] = React.useState(false);

	React.useEffect(() => {
		setLoaded(true);
	}, []);

	return (
		<Box>
			<Fade in={loaded} timeout={4000}>
				<Box className={classes.hero}>
					<Box
						className={classes.overlay}
						// sx={{ justifyContent: { xs: 'flex-end', lg: 'space-around' } }}
					>
						<Box fontFamily='Arial'>
							<Typography
								variant='h2'
								textAlign={'center'}
								sx={{ fontWeight: 800 }}
								color='secondary'
							>
								Rising Sun Foundation
							</Typography>

							<Typography
								variant='h5'
								textAlign={'center'}
								sx={{ fontStyle: 'italic' }}
								color='secondary'
							>
								Improving the lives of people since 2021
							</Typography>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Box>
	);
};

export default Hero;
