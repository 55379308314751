import { Drawer, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { tabs } from './Navbar';
import { Link } from 'gatsby';

const drawerWidth = 240;

const styles = makeStyles({
	drawer: {
		width: drawerWidth,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	activeTab: {
		backgroundColor: '#E0E0E0',
	},
});

function Sidebar({ open, setOpen }) {
	const classes = styles();
	// const location = useLocation();
	// let navigate = useNavigate();

	return (
		<Drawer
			open={open}
			onClose={() => setOpen(!open)}
			sx={{ display: { lg: 'none' } }}
			variant='temporary'
			className={classes.drawer}
			classes={{ paper: classes.drawerPaper }}
			anchor='right'
		>
			<List>
				{tabs.map((tab, key) =>
					key === 2 ? (
						<a href={tab.url} key={key} style={{ textDecoration: 'none' }}>
							<ListItemButton onClick={() => setOpen(!open)}>
								<ListItemText>
									<Typography variant='h6' color='secondary'>
										{tab.name}
									</Typography>
								</ListItemText>
							</ListItemButton>
						</a>
					) : (
						<Link to={tab.url} key={key} style={{ textDecoration: 'none' }}>
							<ListItemButton onClick={() => setOpen(!open)}>
								<ListItemText>
									<Typography variant='h6' color='secondary'>
										{tab.name}
									</Typography>
								</ListItemText>
							</ListItemButton>
						</Link>
					)
				)}
			</List>
		</Drawer>
	);
}

export default Sidebar;
