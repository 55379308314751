import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
// import Events, { MyCard } from '../components/Events';
import Faq from '../components/Faq';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Sponsors from '../components/Sponsors';
import UpcomingEvents from '../components/UpcomingEvents';
import Video from '../components/Video';
// import useStyles from '../styles/index_styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#F5F1E8',
		},
		secondary: {
			main: '#F49C34',
		},
	},
	typography: {
		fontFamily: 'Arial',
	},
});

export default function Home() {
	const [open, setOpen] = React.useState(false);
	// const imgs = [
	// 	{
	// 		src: 'choir1.jpeg',
	// 	},
	// 	{
	// 		src: 'choir2.jpeg',
	// 	},
	// 	{
	// 		src: 'choir3.jpeg',
	// 	},
	// 	{
	// 		src: 'choir4.jpeg',
	// 	},
	// ];

	// const classes = useStyles();

	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<link rel='icon' href='rising_sun3.ico' />
			</Helmet>
			<Navbar open={open} setOpen={setOpen} />
			<Hero />
			<Video
				title='gospel explosion'
				video={'gospel_explosion.mp4'}
				mg={60}
				thumbnail={'gospel_explosion.jpeg'}
			/>
			<Video
				title='gospel sunday'
				video={'moja_love.mp4'}
				mg={10}
				thumbnail={'gospel_sunday.jpeg'}
			/>
			{/* <Events /> */}
			<UpcomingEvents />
			<Sponsors />
			<Faq />
			<Footer />
			<Sidebar open={open} setOpen={setOpen} />
		</ThemeProvider>
	);
}
