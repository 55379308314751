import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../styles/index_styles';
import Marquee from 'react-fast-marquee';

const Faq = () => {
	const classes = useStyles();
	const faq = [
		{
			q: 'What can Rising Sun do for you?',
			a: 'Rising Sun is a foundation that is focused on giving the youth of Mpumalanga a platform to showcase their musical talent. We focus on talents from all fields and genres. Just contact us and we can discuss how we can help your artistic dreams come true.',
		},
		{
			q: 'How can I be part of Rising Sun?',
			a: 'Once the applications are open, a form will be made available for download on the website and social media platforms',
		},
		{
			q: 'What type of talent is Rising Sun looking for?',
			a: 'We are interested in all types of talent.',
		},
		{
			q: 'Is it only for people from Mpumalanga?',
			a: 'No, only 95% of people are from Mpumalanga. Rising Sun will be helping some people from outside the province',
		},
		{
			q: 'What age group and race will Rising Sun empower?',
			a: 'The target market is currently 10 to 35 years of age which can be classified as the youth',
		},
	];
	return (
		<Box>
			<Typography variant='h3' textAlign={'center'} fontWeight={800} color='secondary'>
				Frequently Asked Questions
			</Typography>
			<Marquee speed={50} pauseOnHover pauseOnClick>
				{faq.map(({ q, a }, i) => (
					<Question key={i} question={q} answer={a} classes={classes} />
				))}
			</Marquee>
		</Box>
	);
};

export const Question = ({ question, answer, classes }) => {
	return (
		<Card className={classes.faqCard}>
			<CardContent>
				<Typography variant='h6'>{question}</Typography>
				<Divider variant='middle' />
				<Typography
					variant='subtitle1'
					sx={{
						mt: 5,
					}}
				>
					{answer}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default Faq;
