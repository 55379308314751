import { Box, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import ReactPlayer from 'react-player';
import useStyles from '../styles/index_styles';

const Video = ({ title, video, mg, thumbnail }) => {
	const classes = useStyles();

	const awards = [
		{
			title: 'Album of the Year',
			nominee: 'Rising Sun - The Rising Glory',
			vote: 'INGOMA Album 2140',
			code: 40439,
		},
		{
			title: 'Best Live Recorded DVD',
			nominee: 'Rising Sun - The Rising Glory',
			vote: 'INGOMA CD 2120',
			code: 40439,
		},
		{
			title: 'Song of the Year',
			nominee: 'Rising Sun - Usefikile',
			vote: 'INGOMA Song 2148',
			code: 40439,
		},
		{
			title: 'Best Gospel Group/Duo',
			nominee: 'Rising Sun',
			vote: 'INGOMA Group 2114',
			code: 40439,
		},
		{
			title: 'Best Gospel Collaboration',
			nominee: 'Rising Sun Ft Ayanda Ntanzi',
			vote: 'INGOMA Collaboration 2107',
			code: 40439,
		},
	];

	return (
		<Box marginTop={mg}>
			<Typography
				variant='h3'
				color={'secondary'}
				textAlign='center'
				fontWeight={800}
				fontFamily='Arial'
				textTransform='capitalize'
			>
				{title}
			</Typography>
			<Box
				className={classes.cardVideo}
				sx={{
					flexDirection: { xs: 'column', lg: 'row' },
				}}
			>
				<Box sx={{ width: { lg: '70%' }, boxShadow: '5px 5px 40px #F49C34' }}>
					<ReactPlayer
						controls
						url={video}
						width='100%'
						height='400px'
						light={thumbnail}
						navButtonsAlwaysInvisible
					/>
				</Box>
				{/* <Box
					sx={{
						margin: { xs: '20px 0 0 0', lg: '0 0 0 20px' },
						width: { lg: '30%' },
						display: 'flex',
						flexDirection: 'column',
						height: 300,
						justifyContent: 'center',
					}}
				>
					<Typography variant='h4' textTransform={'uppercase'} fontWeight={700}>
						To Vote
					</Typography>
					<Carousel
						indicators={false}
						sx={{
							fontFamily: 'Arial',
						}}
					>
						{awards.map(({ title, code, nominee, vote }) => (
							<Box key={title}>
								<Typography variant='h6' fontStyle='italic' fontWeight={800}>
									{title}
								</Typography>
								<Typography variant='h6' fontStyle='italic'>
									{nominee}
								</Typography>
								<Typography variant='h6'>
									SMS <i>{vote}</i> to <i>{code}</i>
								</Typography>
							</Box>
						))}
					</Carousel>
				</Box> */}
			</Box>
		</Box>
	);
};

export default Video;
