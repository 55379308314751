import { Box, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
// import Carousel from 'react-material-ui-carousel';
import useStyles from '../styles/index_styles';

const UpcomingEvents = () => {
	const classes = useStyles();
	const events = [
		{
			name: 'Rising Sun Cultural Celebration',
			venue: 'Hoyi Cultural Center',
			url: 'heritage_day.jpeg',
			date: new Date('2022-11-19T08:30:00'),
			end: new Date('2022-11-19T16:00:00'),
		},
		// {
		// 	name: '',
		// 	venue: '',
		// 	url: 'choir2.jpeg',
		// 	date: new Date('2022-09-05'),
		// },
		// {
		// 	name: 'Red Conference Center',
		// 	venue: 'Hatfield, Pretoria',
		// 	url: 'choir3.jpeg',
		// 	date: new Date('2022-10-15'),
		// },
		// {
		// 	name: 'Red Conference Center',
		// 	venue: 'Ushaka Marine World, Durban',
		// 	url: 'choir4.jpeg',
		// 	date: new Date('2022-12-27'),
		// },
	];

	return (
		<Box sx={{ postion: 'static', mt: 10 }}>
			<Typography variant='h3' color='secondary' fontWeight={800}>
				Upcoming Events
			</Typography>
			<Grid container spacing={2} sx={{ p: 5 }}>
				{events.map((event, i) => (
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card
							key={i}
							className={classes.eventCard}
							sx={{
								':hover': {
									bgcolor: '#EEAB3C',
									color: 'white',
								},
							}}
						>
							<CardActionArea
								className={classes.eventCardContainer}
								sx={{
									backgroundImage: `url(${event.url})`,
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
								}}
							>
								<Box className={classes.generalOverlay}>
									<CardContent className={classes.eventCardContainer}>
										<Box className={classes.date}>
											<Box
												className={classes.day}
												sx={{
													':hover': {
														border: '2px solid white',
													},
												}}
											>
												<Typography variant='h4'>
													{event.date.getDate()}
												</Typography>
											</Box>
											<Typography variant='h5'>
												{event.date.getMonth() + 1}
											</Typography>
											<Typography variant='h5'>
												{event.date.getFullYear()}
											</Typography>
										</Box>
									</CardContent>
								</Box>
							</CardActionArea>
						</Card>
						{event.date.getMinutes() ? (
							<Box className={classes.eventText}>
								<Typography
									variant='subtitle1'
									sx={{
										fontSize: '12pt',
										color: 'black',
									}}
								>
									<em>Event: </em>
									{event.name} <br />
									<em>Start: </em>
									{event.date.getHours()} {event.date.getMinutes()}Hrs <br />
									<em>End: </em>
									{event.end.getHours()} {event.end.getMinutes()}Hrs <br />
									<em>Venue: </em>
									{event.venue}
								</Typography>
							</Box>
						) : (
							<Box className={classes.eventText}>
								<Typography
									variant='subtitle1'
									sx={{
										fontSize: '12pt',
										color: 'black',
									}}
								>
									<em>Event: </em>
									{event.name} <br />
									<em>Start: </em>
									{event.date.getHours()} {event.date.getMinutes()}0Hrs <br />
									<em>End: </em>
									{event.end.getHours()} {event.end.getMinutes()}0Hrs <br />
									<em>Venue: </em>
									{event.venue}
								</Typography>
							</Box>
						)}
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default UpcomingEvents;
